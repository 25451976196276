export function getUserInitials(name: string) {
  return name.split(' ').reduce((acc, word) => {
    return acc + word.charAt(0);
  }, '');
}

export function getAvatarColor(name: string) {
  const COLORS = ['#69C2FD', '#069AFC', '#025E9C', '#01385D', '#012842'];

  const nameLength = name.length;
  const colorIndex = nameLength % COLORS.length;

  return COLORS[colorIndex];
}

export function isCoordinator(employee: Personel) {
  return Boolean(employee.roles?.some((role) => role.name === 'coordinator'));
}

export function getRole(role: string) {
  if (role === 'coordinator') {
    return 'Koordynator';
  }

  if (role === 'nurse') {
    return 'Pielęgniarka';
  }

  if (role === 'medic') {
    return 'Lekarz';
  }

  if (role === 'super_admin') {
    return 'Super Administrator';
  }

  if (role === 'admin') {
    return 'Administrator';
  }

  if (role === 'employer') {
    return 'Pracownik';
  }

  return 'Nieznana rola';
}
